<template>
  <landing-scaffold class="register-page">
    <template v-slot:default="{openLogin}">
      <div class="form-page">
        <div class="columns">
          <div class="column col-6 col-md-12 d-flex flex-column justify-center position-relative p-0">
            <div class="top-menu navbar form-page__nav d-flex justify-content-between align-items-center">
              <a class="navbar__brand" :href="`/${$i18n.locale}/`">
                <img src="@/assets/landing/logo.svg" alt="logo">
              </a>

              <div class="d-flex align-center hide-md">
                <div class="form-page__nav__text">
                  {{ $t('menu.login_question') }}
                </div>
                <div
                  class="btn btn-link btn-in-nav pr-0"
                  @click="openLogin()"
                >
                  {{ $t('menu.login') }}
                </div>
              </div>
            </div>
            <div id="registration-form" class="d-flex flex-column h-100" />
          </div>
          <register-right-banner />
        </div>
      </div>
    </template>
  </landing-scaffold>
</template>

<script>
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import LandingScaffold from '@/pages/LandingScaffold'
import RegisterRightBanner from './RegisterRightBanner.vue'

const TYPEFORM_REGISTRATION_FORM_ID = 'BTGIGiz5'

export default {
  components: {
    LandingScaffold,
    RegisterRightBanner
  },

  mixins: [],

  data () {
    return {
      TYPEFORM_REGISTRATION_FORM_ID
    }
  },

  computed: {

  },

  created () {
  },

  mounted () {
    this.$root.$children[0].posthogOnReadyPush(() => {
      createWidget(TYPEFORM_REGISTRATION_FORM_ID, {
        container: document.querySelector('#registration-form'),
        hideHeaders: true,
        hideFooter: true,
        opacity: 0,
        onSubmit: ({ formId, responseId }) => {
          this.$root.$children[0].posthogCapture('Registered Typeform', { responseId })
        },
        hidden: {
          posthog_session_id: this.$root.$children[0].posthogGetDistinctID()
        }
      })
    })
  },

  methods: {
  }
}

</script>

<style lang=scss>
  @import '~@/css/landing/landing.scss';
</style>

<i18n locale='en' src='@/i18n/en/landing/Register.json' />
<i18n locale='de' src='@/i18n/de/landing/Register.json' />
<i18n locale='en' src='@/i18n/en/landing/Global.json' />
<i18n locale='de' src='@/i18n/de/landing/Global.json' />